import React, { Fragment, useRef } from "react"
import { graphql } from "gatsby"
import { Icon } from '@iconify/react';
import styled from "styled-components"
import _map from "lodash/map"
import { GatsbyImage } from 'gatsby-plugin-image';
import FormatDate  from '../components/formatDate'
import { Fade } from "react-awesome-reveal"
import { MdNavigateBefore, MdNavigateNext, MdDateRange } from 'react-icons/md'
import BtnPrimary from "../components/buttons/ButtonRounded"
import { StructuredText } from 'react-datocms'
import {
  Banner,
  PageWrapper,
  PageInner,
  PageTitle,
  Spacer,
  Text,
} from "../components/Elements"
import { colors, mq } from "../consts/style"
import Seo from "../components/seo"
import Boop from "../components/boop"
import PlayerSoundcloud from "../components/playerSoundcloud"
import Video from "../components/video"
import Link from '../components/ExtendedLink';


const Legende = styled.div`
  text-align:right;
  font-size:1.4rem;
  margin-bottom:2rem;
  margin-top:-3rem;
  font-style:italic;
`

const PageInnerNews = styled.div`
  width: 100%;
  position: relative;
  display: grid;

  grid-template-columns: 1fr 2fr;
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  grid-gap: 5rem;

  ${mq.tabletSmall` 
  grid-template-columns: 1fr;
  grid-template-columns:minmax(0, 1fr);
  grid-gap:1rem;
  `}
`


const LeftContainer =   styled.div`

  padding: 2.5rem;
  padding-right:5rem;
  color:${colors.blue};
  ${mq.tabletSmall` 
    padding:0;
  `}
`



const NewsItemDate =   styled.div`
    width:100%;

  color:${colors.dark};
  svg {vertical-align:top;margin-right:0.8rem;font-size:2.4rem;}
  svg path:first-of-type {
   fill: ${colors.yellow};
  }
 
`


const BlogPostHead = styled.div`
position:relative;
display:flex;
justify-content:flex-end;
align-items:center;
max-height:80rem;
min-height:60rem;
overflow:hidden;
`


const BlogTitle = styled(PageTitle)`
  color:white!important;
  line-height: 59px;
  padding:6rem;
  ${mq.tabletSmall` 
    padding:4rem;
  `}
  &:before {
    background-color:white!important;
  }

`

const Image = styled(GatsbyImage)`
  position: absolute!important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*z-index: -1;*/

  /* Adjust image positioning (if image covers area with defined height) and add font-family for polyfill*/
  & > img {

    object-fit:cover;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
`


const BgGradient = styled.div`
  position: absolute!important;
  top: 0;
  left: 0;
  height:100%;
  width: 100%;
 /* z-index: -1;*/
  background: linear-gradient(to right,#405E5E, #7C9E9E);
  
  opacity:0.5;
`


const BlogPostContent = styled.div`
  padding-top:2.5rem;
  ${mq.tabletSmall` 
    padding-top:1rem;
  `}
  p {
    padding-bottom:2.2rem;
  }
  ul {
    list-style:disc;
    margin-left: 3.2rem;
    padding-bottom: 3.2rem;
    li p {
      padding-bottom:0;
    }
    li:not(:last-child) {
      padding-bottom:0;
    }
  }
  .gatsby-image-wrapper {
    margin-top: -1.5rem;
    margin-bottom: 3.2rem;
  }
  a {
    color:${colors.text2};
    text-decoration:underline;
  } 
`



const BlogPost = ({ data, pageContext, location }) => {
  const {
    titre,
    image,
    date,
    contenu,
    content,
    encartContacts,
    encartTransports,
    meta,
    seoMetaTags
  } = data.blog
  const {  next, previous } = pageContext

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <BlogPostHead>      
        <Image image={image.gatsbyImageData} alt={titre} />
        <BgGradient/>
        <PageInner>
          <Fade bottom >
            <BlogTitle>{titre}</BlogTitle>
           </Fade>
        </PageInner>
       
      </BlogPostHead>
      <PageWrapper>
      <PageInner>
        <PageInnerNews>
          <LeftContainer>
                  <NewsItemDate>
                    <Icon title="Date" icon="ant-design:calendar-twotone" style={{color: colors.dark}} />
                    <span>{date}</span>
                  </NewsItemDate>  
          </LeftContainer>
     <BlogPostContent>   
    <StructuredText
      data={content}   
   /*   renderInlineRecord={({ record }) => {
        switch (record.__typename) {
         case 'DatoCmsTexte':
           return <Text>text</Text>
       
          default:
            console.log("inline:", record)
            return null;
        }
      }} */
      renderBlock={({ record }) => {
        switch (record.__typename) {
         
          case 'DatoCmsImage':
              return <>
              <GatsbyImage image={record.image.gatsbyImageData} alt="illustration"/>
              <Legende>{record.legende}</Legende></>
          case 'DatoCmsPlayerSoundcloud':
              return <PlayerSoundcloud urlPlayer={record.url} />
          case 'DatoCmsVideo':
              return <Video videoSrcURL={`https://www.youtube.com/embed/`+record.video.providerUid} videoTitle={record.titre}/>
              
          default:
              return null;
          }
      }}
    />
        {previous && <Link className="no-hover"  style={{marginRight: '3.2rem'}} to={`/blog/${previous.slug}/`} ><MdNavigateBefore style={{verticalAlign: 'sub',}}/> <span className="link-effect">Article précédent</span></Link>}
      {next && <Link className="no-hover" to={`/blog/${next.slug}/`} ><span className="link-effect">Article suivant</span> <MdNavigateNext style={{verticalAlign: 'sub'}}/></Link>}

  </BlogPostContent>
         
        
        </PageInnerNews>

    
        </PageInner>
      
      </PageWrapper>
    </Fragment>
  )
}

export const blogQuery = graphql`
  query($slug: String!, $locale: String!) {
    blog: datoCmsBlog(slug: { eq: $slug }, locale: { eq: $locale }) {
      titre
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      date(formatString: "ll", locale:"fr")
      content {
        blocks {
          __typename
          ... on DatoCmsPlayerSoundcloud {
            id: originalId
            url
          }
          ... on DatoCmsImage {
            id: originalId
            image {
              gatsbyImageData
            }
            legende
          }
          ... on DatoCmsTexte {
            id: originalId
            texte
          }
          ... on DatoCmsVideo {
            id: originalId
            video {
              url
              providerUid
            }
            titre
          }
        }
        links
        value
      }
      meta {
        createdAt
      }
      id
      image{
        gatsbyImageData(  
          placeholder: BLURRED,
          forceBlurhash: false,
          width:2200 
        )
      }
  
    }
  }
`

export default BlogPost