import React from 'react';




const PlayerSoundcloud = ({urlPlayer, ...props}) => {
 /*console.log(props)*/
return (
   <iframe 
  width="100%" 
  height="300" 
  scrolling="no" 
  frameBorder="no" 
  allow="autoplay" 
  src={'https://w.soundcloud.com/player/?url='+urlPlayer+'&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=true'}>
    </iframe>
  
   
         
   
     
)
 }
export default PlayerSoundcloud;
